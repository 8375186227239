var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('Loading',{attrs:{"isLoading":_vm.isLoading}}),_c('nav',{staticClass:"navbar navbar-expand-sm justify-content-between"},[_c('h1',{staticClass:"mb-0 navbar-brand"},[_c('router-link',{class:{ 'text-black': _vm.nowNav == 'contact' || _vm.nowNav =='series' },attrs:{"to":"/"}},[_vm._v("S-D STUDIO")])],1),_c('button',{staticClass:"btn d-block d-md-none px-0",attrs:{"type":"button"},on:{"click":function($event){_vm.isNavOpen = !_vm.isNavOpen}}},[_c('i',{staticClass:"fas fa-bars text-white",class:{ 'text-black': _vm.nowNav == 'contact' || _vm.nowNav =='series' }})]),_c('div',{staticClass:"flex-row-reverse",class:{ 'active': _vm.isNavOpen },attrs:{"id":"mainNavbar"}},[_c('ul',{staticClass:"d-sm-flex main-item"},[_c('li',{staticClass:"d-block d-md-none"},[_c('span',{staticClass:"text-white text-right",on:{"click":function($event){_vm.isNavOpen = !_vm.isNavOpen}}},[_vm._v("×")])]),_c('li',[_c('router-link',{staticClass:"nav-item line-style",class:{
                'black': _vm.nowNav == 'contact' || _vm.nowNav =='series',
                'line-on': _vm.nowNav == 'home',},attrs:{"to":"/"},nativeOn:{"click":function($event){_vm.isNavOpen = !_vm.isNavOpen}}},[_vm._v(" HOME ")])],1),_c('li',[_c('router-link',{staticClass:"nav-item line-style",class:{
                'black': _vm.nowNav == 'contact' || _vm.nowNav =='series',
                'line-on': _vm.nowNav == 'projects',},attrs:{"to":"/projects/all"},nativeOn:{"click":function($event){_vm.isNavOpen = !_vm.isNavOpen}}},[_vm._v(" PROJECTS ")])],1),_c('li',[_c('router-link',{staticClass:"nav-item line-style",class:{
                'black': _vm.nowNav == 'contact' || _vm.nowNav =='series',
                'line-on': _vm.nowNav == 'post',},attrs:{"to":"/postproduction/all"},nativeOn:{"click":function($event){_vm.isNavOpen = !_vm.isNavOpen}}},[_vm._v(" POST-PRODUCTION ")])],1),_c('li',[_c('router-link',{staticClass:"nav-item line-style",class:{
                'black': _vm.nowNav == 'contact' || _vm.nowNav =='series',
                'line-on': _vm.nowNav == 'film',},attrs:{"to":"/film/all"},nativeOn:{"click":function($event){_vm.isNavOpen = !_vm.isNavOpen}}},[_vm._v(" FILM ")])],1),_c('li',[_c('router-link',{staticClass:"nav-item line-style",class:{
                'black': _vm.nowNav == 'contact' || _vm.nowNav =='series',
                'line-on': _vm.nowNav == 'contact',},attrs:{"to":"/contact"},nativeOn:{"click":function($event){_vm.isNavOpen = !_vm.isNavOpen}}},[_vm._v(" CONTACT ")])],1)])])]),_c('router-view'),_c('footer',{staticClass:"bg-black px-4 px-md-0 d-flex d-lg-block justify-content-md-center",class:{ 'white-style': _vm.nowNav == 'single' }},[_c('ul',{staticClass:"d-flex justify-content-center align-items-lg-center text-white"},[_c('li',{staticClass:"d-flex align-items-center basic-info spacing"},[(_vm.isWhite)?_c('img',{attrs:{"src":require("@/assets/images/logo/s-d-logo_white.png"),"alt":"S-D STUDIO Logo"}}):_c('img',{attrs:{"src":require("@/assets/images/logo/s-d-logo_black.png"),"alt":"S-D STUDIO Logo"}}),_vm._m(0)]),_vm._m(1),_vm._m(2)])]),_c('div',{staticClass:"to-top",class:{ active: _vm.toTop == true },on:{"click":_vm.scrollToTop}},[_vm._m(3)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[_c('li',[_vm._v("思帝影像事業企業社")]),_c('li',[_vm._v("S-D STUDIO")]),_c('li',{staticClass:"social"},[_c('a',{attrs:{"href":"https://www.facebook.com/selfdirectedstudio","target":"_blank"}},[_c('i',{staticClass:"fab fa-facebook-f"})]),_c('a',{attrs:{"href":"https://www.youtube.com/channel/UCGawabA6bnVLKxzzDdd5r0Q","target":"_blank"}},[_c('i',{staticClass:"fab fa-youtube"})]),_c('a',{attrs:{"href":"https://www.instagram.com/selfdirectedstudio/","target":"_blank"}},[_c('i',{staticClass:"fab fa-instagram"})])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"address spacing"},[_c('p',[_vm._v(" 710 台南市永康區富強路一段98巷38弄3號 "),_c('br'),_vm._v("1F., No. 3, Aly. 38, Ln. 98, "),_c('br'),_vm._v("Sec. 1, Fuqiang Rd., Yongkang Dist., Taiwan ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"email spacing"},[_c('p',[_vm._v("sdstudio@sdstudiotw.com")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn-style"},[_c('i',{staticClass:"fas fa-arrow-up"})])}]

export { render, staticRenderFns }